import React, { Component } from 'react'
import Planet from './planet.png'
import './Oldschool.css'
import { Mixcloud } from '@icons-pack/react-simple-icons'
class Oldschool extends Component {
    render() {
        return (
            <section id="oldschool" className="oldschool">
                <div className="container">
                    <header className="d-flex justify-content-center">
                        <h2>Radioshows</h2>
                    </header>
                    <hr className="rounded" />

                    <div className="row">
                        <div className="col-lg-6 d-flex justify-content-center">
                            <img
                                src={Planet}
                                alt="planet_radio"
                                //width={'50%'}
                                //className='hero-img'
                            />
                        </div>
                        <div className="col-lg-6 d-flex flex-column justify-content-center">
                            <h3>planet radio oldschool</h3>
                            <div>
                                <p>
                                    Seit dem Jahr 2022 ist DJ M.i.B. Resident DJ
                                    bei{' '}
                                    <a href="https://www.planetradio.de/musik/dj-sets/oldschool.html">
                                        planet radio oldschool
                                    </a>
                                    . Dort könnt ihr regelmäßig seine Sets
                                    bestehend aus den besten Oldschool Tracks
                                    der 2000er live im Radio hören. Aktuell
                                    jeden zweiten Mittwoch im Monat - von
                                    21.50-23.50 Uhr. Einige Sets gibt es auch
                                    auf seinem{' '}
                                    <a href="https://www.mixcloud.com/DJ_MiB/">
                                        Mixcloud
                                    </a>{' '}
                                    Account.
                                </p>
                            </div>
                            <div className="d-flex justify-content-center">
                                <a
                                    href="https://www.mixcloud.com/DJ_MiB/"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <Mixcloud size={60} />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default Oldschool
