import React, { Component } from 'react'
import './Dates.css'

const sortDates = (dates) => {
    const yesterday = new Date()
    yesterday.setDate(yesterday.getDate() - 1)
    const monthMapping = {
        januar: '01',
        februar: '02',
        märz: '03',
        marz: '03',
        april: '04',
        mai: '05',
        juni: '06',
        juli: '07',
        august: '08',
        september: '09',
        oktober: '10',
        november: '11',
        dezember: '12',
    }
    return dates
        .filter((date) => {
            // Filter out dates in the past or falsey values
            //`YYYY-MM-DDTHH:mm:ss.sssZ`
            return (
                date &&
                new Date(
                    date.year +
                        '-' +
                        monthMapping[date.month.toLocaleLowerCase()] +
                        '-' +
                        date.day +
                        'T00:00:00.000Z'
                ) >= yesterday
            )
        })
        .sort((a, b) => {
            const a1 = new Date(
                a.year +
                    '-' +
                    monthMapping[a.month.toLocaleLowerCase()] +
                    '-' +
                    a.day +
                    'T00:00:00.000Z'
            )
            const b1 = new Date(
                b.year +
                    '-' +
                    monthMapping[b.month.toLocaleLowerCase()] +
                    '-' +
                    b.day +
                    'T00:00:00.000Z'
            )
            return a1 < b1 ? -1 : a1 > b1 ? 1 : 0
        })
}

class Dates extends Component {
    state = {
        dates: [
            {
                day: '09',
                month: 'Februar',
                year: '2024',
                event: '2000er Party - Fasching Spezial',
                location: 'S-Club',
                city: 'Fulda',
            },
            {
                day: '10',
                month: 'Januar',
                year: '2024',
                event: 'Hüttengaudi',
                location: 'Föllsche Hütte',
                city: 'Fulda',
            },
            {
                day: '12',
                month: 'Februar',
                year: '2024',
                event: 'Rosenmontag - After Zug Party',
                location: 'S-Club',
                city: 'Fulda',
            },
            {
                day: '01',
                month: 'März',
                year: '2024',
                event: 'Sugar Pop Bash',
                location: 'S-Club',
                city: 'Fulda',
            },
            {
                day: '08',
                month: 'März',
                year: '2024',
                event: 'Throwback Times',
                location: 'S-Club',
                city: 'Fulda',
            },
            {
                day: '16',
                month: 'März',
                year: '2024',
                event: 'Nachtresidenz',
                location: 'S-Club',
                city: 'Fulda',
            },
            {
                day: '31',
                month: 'März',
                year: '2024',
                event: 'Happy Easter Club Party',
                location: 'Lokschuppen',
                city: 'Bebra',
            },
            {
                day: '06',
                month: 'April',
                year: '2024',
                event: 'Nachtresidenz x Barmucke',
                location: 'S-Club',
                city: 'Fulda',
            },
            {
                day: '12',
                month: 'April',
                year: '2024',
                event: 'Support for Luca-Dante Spadafora und Niklas Dee',
                location: 'S-Club',
                city: 'Fulda',
            },
            {
                day: '19',
                month: 'April',
                year: '2024',
                event: 'TBA',
                location: 'Look',
                city: 'Bad Kissingen',
            },
            {
                day: '27',
                month: 'April',
                year: '2024',
                event: 'Private Birthday',
                location: 'Secret',
                city: 'Fulda',
            },
            {
                day: '03',
                month: 'Mai',
                year: '2024',
                event: 'Kirmes Friedewald w/ Klinkenpraxis',
                location: 'Kirmes',
                city: 'Friedewald',
            },
            {
                day: '08',
                month: 'Mai',
                year: '2024',
                event: 'TBA',
                location: 'S-Club',
                city: 'Fulda',
            },
            {
                day: '19',
                month: 'Mai',
                year: '2024',
                event: 'Throwback Times',
                location: 'S-Club',
                city: 'Fulda',
            },
            {
                day: '25',
                month: 'Mai',
                year: '2024',
                event: 'Nachtresidenz',
                location: 'S-Club',
                city: 'Fulda',
            },
            {
                day: '14',
                month: 'Juni',
                year: '2024',
                event: 'Abiparty',
                location: 'S-Club',
                city: 'Fulda',
            },
            {
                day: '28',
                month: 'Juni',
                year: '2024',
                event: 'Abiparty',
                location: 'S-Club',
                city: 'Fulda',
            },
            {
                day: '03',
                month: 'Juli',
                year: '2024',
                event: 'Abschlussball Konrad-Adenauer-Schule',
                location: 'Propsteihaus',
                city: 'Petersberg',
            },
            {
                day: '28',
                month: 'September',
                year: '2024',
                event: 'Wedding',
                location: 'Private',
                city: 'Fulda',
            },
            {
                day: '23',
                month: 'August',
                year: '2024',
                event: 'Wedding',
                location: 'Private',
                city: 'Grebenhain',
            },
            {
                day: '24',
                month: 'August',
                year: '2024',
                event: 'Nachtresidenz',
                location: 'S-Club',
                city: 'Fulda',
            },
            {
                day: '31',
                month: 'August',
                year: '2024',
                event: 'Wedding',
                location: 'Private',
                city: 'Kassel',
            },
            {
                day: '07',
                month: 'September',
                year: '2024',
                event: 'Wedding',
                location: 'Private',
                city: 'Fulda',
            },
            {
                day: '14',
                month: 'September',
                year: '2024',
                event: 'Wedding',
                location: 'Private',
                city: 'Fulda',
            },
            {
                day: '19',
                month: 'September',
                year: '2024',
                event: 'Oktoberfest VR Bank',
                location: 'Private',
                city: 'Fulda',
            },
            {
                day: '28',
                month: 'September',
                year: '2024',
                event: 'Wedding',
                location: 'Private',
                city: 'Fulda',
            },
            {
                day: '03',
                month: 'Oktober',
                year: '2024',
                event: 'Afterwork - 70s & 80s',
                location: 'Platzhirsch',
                city: 'Fulda',
            },
            {
                day: '04',
                month: 'Oktober',
                year: '2024',
                event: 'Wedding',
                location: 'Private',
                city: 'Fulda',
            },
            {
                day: '29',
                month: 'November',
                year: '2024',
                event: 'TBA',
                location: 'S-Club',
                city: 'Fulda',
            },
            {
                day: '06',
                month: 'Dezember',
                year: '2024',
                event: 'Nuthouse Festival ',
                location: 'Hessenhalle',
                city: 'Alsfeld',
            },
            {
                day: '14',
                month: 'Dezember',
                year: '2024',
                event: 'Private Event',
                location: 'S-Club',
                city: 'Fulda',
            },
            {
                day: '25',
                month: 'Dezember',
                year: '2024',
                event: 'Throwback Times',
                location: 'S-Club',
                city: 'Fulda',
            },
            {
                day: '31',
                month: 'Dezember',
                year: '2024',
                event: 'Happy New Year',
                location: 'S-Club',
                city: 'Fulda',
            },
        ]
    }

    renderRow = (date, index) => {
        return (
            <div key={index} className="date-wrapper">
                <p className="date-row">
                    <span className="date-row-date">
                        {date.day}. {date.month}
                    </span>{' '}
                    • {date.location ? date.location + ' • ' : ''}
                    {date.city}
                </p>
                <p className="date-row-event">{date.event}</p>
            </div>
        )
    }

    render() {
        const dates = sortDates(this.state.dates)
        if (dates.length <= 0) {
            return null
        }

        return (
            <section id="dates" className="dates">
                <div className="container">
                    <header className="d-flex justify-content-center">
                        <h2>Dates</h2>
                    </header>
                    <hr className="rounded" />

                    {dates.map(this.renderRow)}
                </div>
            </section>
        )
    }
}

export default Dates
