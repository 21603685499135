import React, { Component } from 'react';
import './Footer.css';
import { Button } from 'react-bootstrap';

class Footer extends Component {
    state = {
        impressumOpen: false,
    };
    handleImpressumClick = () => {
        const { impressumOpen } = this.state;
        this.setState({ impressumOpen: !impressumOpen });
    };

    render() {
        return (
            <section id='footer' className='footer d-flex'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-6'>
                            <h4>Booking</h4>
                            <p>
                                DJ M.i.B. für deine Party? Kein Problem! <br />
                                Egal ob Club-Event, Festival, Hochzeit oder
                                Firmenfeier, schreib einfach eine E-Mail für ein
                                unverbindliches Angebot:{' '}
                                <a href='mailto:booking@djmib.de'>
                                    booking@djmib.de
                                </a>
                            </p>
                            {/* <Button
                                onClick={this.handleImpressumClick}
                                variant='link'
                                style={{
                                    textDecoration: 'none',
                                    paddingLeft: 0,
                                    marginLeft: 0,
                                    color: '#d1d2d8',
                                }}
                            >
                                Impressum
                            </Button> */}
                        </div>
                        <div className='col-lg-3 d-flex flex-column footer-links'>
                            <h4>Topics</h4>
                            <a href='#about'>About</a>
                            <a href='#dates'>Dates</a>
                            <a href='#oldschool'>Radioshows</a>
                            <a href='#references'>Referenzen</a>
                            <a href='#socials'>Socials</a>
                        </div>
                        <div className='col-lg-3 d-flex flex-column footer-links'>
                            <h4>Others</h4>
                            <a href='https://www.mehrbums.de/'>Mehrbums</a>
                            <a href='https://www.s-club-fulda.de/'>
                                S-Club Fulda
                            </a>

                            <a href='https://alteschule-fulda.de/'>
                                Alte Schule Fulda
                            </a>

                            <a href='https://www.z1-wallace.de/'>
                                Club Z1 Hünfeld
                            </a>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Footer;
