import React, { Component } from 'react';
import './Divider.css';

class Divider extends Component {
    render() {
        return <section id='divider' className={this.props.cssClass}></section>;
    }
}

export default Divider;
