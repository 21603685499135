import React, { Component } from 'react';
import './WideImage.css';

class WideImage extends Component {
    render() {
        return (
            <section
                id='wide-image'
                className={this.props.cssClass + ' wide-image'}
            >
                <div className='container'>
                    <img
                        src={this.props.image}
                        alt='Main'
                        className='wide-img'
                    />
                </div>
            </section>
        );
    }
}

export default WideImage;
