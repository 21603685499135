import React, { Component } from 'react';
import './Navbar.css';
import Hamburger from 'hamburger-react';
import { Button, Collapse } from 'react-bootstrap';

class Navbar extends Component {
    state = {
        open: false,
    };

    toggleMenu = () => {
        const { open } = this.state;
        this.setState({ open: !open });
    };

    handleNavbarClick = (e) => {
        this.setState({ open: false });
    };

    render() {
        const { open } = this.state;

        return (
            <>
                <nav id='navbar' className='my-navbar'>
                    <div className='container d-flex'>
                        <div className='hamburger'>
                            <Hamburger
                                toggled={open}
                                toggle={this.toggleMenu}
                            />
                        </div>
                    </div>
                    <div className='nav-list-container d-flex justify-content-center'>
                        <div className='nav-list-items'>
                            <Collapse in={open}>
                                <div
                                    id='nav-list-container'
                                    className='my-nav-list-container'
                                >
                                    <Button
                                        onClick={this.handleNavbarClick}
                                        variant='link'
                                        href='#about'
                                        style={{
                                            width: '100%',
                                            margin: '0 auto',
                                            textDecoration: 'none',
                                            color: '#eebe46',
                                            fontSize: '24px',
                                            height: '70px',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                    >
                                        About
                                    </Button>
                                    <Button
                                        onClick={this.handleNavbarClick}
                                        variant='link'
                                        href='#dates'
                                        style={{
                                            width: '100%',
                                            margin: '0 auto',
                                            textDecoration: 'none',
                                            color: '#eebe46',
                                            fontSize: '24px',
                                            height: '70px',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                    >
                                        Dates
                                    </Button>
                                    <Button
                                        onClick={this.handleNavbarClick}
                                        variant='link'
                                        href='#oldschool'
                                        style={{
                                            width: '100%',
                                            margin: '0 auto',
                                            textDecoration: 'none',
                                            color: '#eebe46',
                                            fontSize: '24px',
                                            height: '70px',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                    >
                                        Radioshows
                                    </Button>
                                    <Button
                                        onClick={this.handleNavbarClick}
                                        variant='link'
                                        href='#references'
                                        style={{
                                            width: '100%',
                                            margin: '0 auto',
                                            textDecoration: 'none',
                                            color: '#eebe46',
                                            fontSize: '24px',
                                            height: '70px',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                    >
                                        Referenzen
                                    </Button>
                                    <Button
                                        onClick={this.handleNavbarClick}
                                        variant='link'
                                        href='#socials'
                                        style={{
                                            width: '100%',
                                            margin: '0 auto',
                                            textDecoration: 'none',
                                            color: '#eebe46',
                                            fontSize: '24px',
                                            height: '70px',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                    >
                                        Socials
                                    </Button>
                                    <Button
                                        onClick={this.handleNavbarClick}
                                        variant='link'
                                        href='#footer'
                                        style={{
                                            width: '100%',
                                            margin: '0 auto',
                                            textDecoration: 'none',
                                            color: '#eebe46',
                                            fontSize: '24px',
                                            height: '70px',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                    >
                                        Booking
                                    </Button>
                                </div>
                            </Collapse>
                        </div>
                    </div>
                </nav>
            </>
        );
    }
}

export default Navbar;
