import React, { Component } from 'react'
import './About.css'

class About extends Component {
    render() {
        return (
            <section id="about" className="about">
                <div className="container">
                    <header className="d-flex justify-content-center">
                        <h2>About</h2>
                    </header>
                    <hr className="rounded" />
                    <p>
                        Im Jahr 2013 gewann DJ M.i.B. den Red Bull DJ Contest
                        des S-Club Fulda und gehört seitdem zu den Resident DJs
                        des S-Club Fulda. Es folgten regelmäßige Bookings bei
                        Partyreihen, wie Mehrbums, Nachtresidenz, Torso House
                        Lounge, Throwback Times, 90er & 2000er Party und vielen
                        mehr. Zudem stand er schon mit Größen wie Neelix, Harris
                        & Ford, HBz, Le Shuuk, Mashup-Germany, Mausio, Fabian
                        Farell und vielen mehr hinter den Decks.
                    </p>
                    <p>
                        Auch außerhalb von Fulda folgten Bookings und
                        Veranstaltungen. So konnte DJ M.i.B. bereits im Rahmen
                        des DJ-Contests auf dem Mainfloor des Bootshauses in
                        Köln auflegen. Weitere Bookings in Köln, Gießen, Bad
                        Hersfeld und zusätzlichen Städten kamen hinzu.
                    </p>
                    <p>
                        Neben Club-Gigs legt DJ M.i.B. auch auf Hochzeiten und
                        Events auf. Dabei legt er großen Wert auf die
                        Bedürfnisse des Publikums und bringt durch sein feines
                        Gespür für die Gäste jede Party auf ein neues Level.
                        Durch seine breit aufgestellte Musiksammlung bringt DJ
                        M.i.B. jedes Publikum zum Tanzen und sorgt für eine
                        unvergessliche Party.
                    </p>
                </div>
            </section>
        )
    }
}

export default About
