import './App.css';
import Hero from './pages/01Hero/Hero';
import About from './pages/About/About';
import Dates from './pages/Dates/Dates';
import Oldschool from './pages/Oldschool/Oldschool';
import References from './pages/References/References';
import Socials from './pages/Socials/Socials';
import Footer from './pages/Footer/Footer';
import Navbar from './pages/Navbar/Navbar';
import WideImage from './pages/WideImage/WideImage';
import Wide from './pages/WideImage/wide2.jpg';
import Wide2 from './pages/WideImage/wide-hands-2.jpg';
import Divider from './pages/Divider/Divider';

function App() {
    return (
        <div className='App'>
            <Navbar />
            <Hero />
            <Divider cssClass='divider-1' />
            <main id='main'>
                <About />
                <Divider cssClass='divider-2' />
                <Dates />
                <WideImage image={Wide} cssClass='divider-6' />
                <Oldschool />
                <Divider cssClass='divider-1' />
                <References />
                <WideImage image={Wide2} cssClass='divider-5' />
                <Socials />
                <Divider cssClass='divider-3' />
            </main>
            <Footer />
        </div>
    );
}

export default App;
