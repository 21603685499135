import React, { Component } from 'react'
import './References.css'

const references = [
    {
        city: 'Fulda',
        location: 'S-Club',
    },
    {
        city: 'Fulda',
        location: 'Löwe',
    },
    {
        city: 'Fulda',
        location: 'Alte Schule',
    },
    {
        city: 'Fulda',
        location: 'Kulturzentrum Kreuz',
    },
    {
        city: 'Fulda',
        location: 'Neidclub',
    },
    {
        city: 'Fulda',
        location: 'Hochschultage - Campus',
    },
    {
        city: 'Köln',
        location: 'Bootshaus',
    },
    {
        city: 'Köln',
        location: 'Red Cat Lounge',
    },
    {
        city: 'Gießen',
        location: 'Monkeys',
    },
    {
        city: 'Hünfeld',
        location: 'Club Z1',
    },
    {
        city: 'Bad Kissingen',
        location: 'Look - Klein.Stadt.Klub',
    },
    {
        city: 'Bad Hersfeld',
        location: 'MaXXim',
    },
    {
        city: 'Bebra',
        location: 'Lokschuppen',
    },
]

class References extends Component {
    renderRow = (reference, index) => {
        return (
            <div key={index} className="row justify-content-center club-list">
                <div className="col">{reference.city}</div>
                <div className="col-auto">|</div>
                <div className="col">{reference.location}</div>
            </div>
        )
    }

    renderRows = () => {}

    render() {
        return (
            <section id="references" className="references">
                <div className="container">
                    <header className="d-flex justify-content-center">
                        <h2>Referenzen</h2>
                    </header>
                    <hr className="rounded" />

                    {references.map(this.renderRow)}
                </div>
            </section>
        )
    }
}

export default References
