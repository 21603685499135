import React, { Component } from 'react';
import MiB_Img from './mib_header_1.jpg';
import MiB_Logo from './mib_logo.png';
import './Hero.css';

class Hero extends Component {
    render() {
        return (
            <section id='hero' className='hero d-flex'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-6 order-lg-2 d-flex flex-column justify-content-center'>
                            <img
                                src={MiB_Img}
                                alt='Main'
                                className='hero-img'
                            />
                        </div>
                        <div className='col-lg-6 oder-lg-1 d-flex flex-column justify-content-center'>
                            <img
                                src={MiB_Logo}
                                alt='Logo'
                                className='hero-logo'
                            />
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Hero;
