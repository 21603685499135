import React, { Component } from 'react';
import {
    Facebook,
    Soundcloud,
    Mixcloud,
    Instagram,
} from '@icons-pack/react-simple-icons';

import './Socials.css';

class Socials extends Component {
    render() {
        return (
            <section id='socials' className='socials'>
                <div className='container'>
                    <header className='d-flex justify-content-center'>
                        <h2>Socials</h2>
                    </header>
                    <hr className="rounded"/>

                    <div className='d-flex justify-content-center'>
                        <a
                            href='https://www.instagram.com/mib122/'
                            target='_blank'
                            rel='noreferrer'
                        >
                            <Instagram size={60} />
                        </a>
                        <a
                            href='https://www.facebook.com/deejaymib/'
                            target='_blank'
                            rel='noreferrer'
                        >
                            <Facebook size={60} />
                        </a>
                        <a
                            href='https://soundcloud.com/djmib'
                            target='_blank'
                            rel='noreferrer'
                        >
                            <Soundcloud size={60} />
                        </a>
                        <a
                            href='https://www.mixcloud.com/DJ_MiB/'
                            target='_blank'
                            rel='noreferrer'
                        >
                            <Mixcloud size={60} />
                        </a>
                    </div>
                </div>
            </section>
        );
    }
}

export default Socials;
